const translations = {
  404: 'Pod podanym adresem nie można znaleźć endpointu',
  500: 'Wystąpił wewnętrzny błąd serwera. Spróbuj odświeżyć stronę',
  'product-add-title': 'Dodawanie produktu',
  'product-edit-title': 'Zarządzanie produktem',
  'edit-product': 'Edytuj produkt',
  'delete-product': 'Usuń produkt',
  'duplicate-product': 'Duplikuj produkt',
  'combined-product': 'Produkty powiązane',
  'stock-product': 'Stan magazynowy',
  'edit-categories': 'Edytuj kategorię',
  'delete-categories': 'Usuń kategorię',
  'tag-filter-name': 'Nazwa tagu',
  'categories-filter-name': 'Nazwa kategorii',
  'page-productList': 'Lista produktów',
  'page-categoryList': 'Lista kategorii',
  'page-tagList': 'Lista tagów',
}

export default translations
