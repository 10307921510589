import {getApiItem, getApiList} from "@/api/backend";
import moment from "moment";

const state = {
  item: {},
  apiConnectionErr: {
    isError: false,
    status: null,
  },
};

const mutations = {
  setItem(state, item = {}) {
    state.item = item;
  },
  setApiError(state, hasError) {
    state.apiConnectionErr = hasError
  },
  resetItem(state) {
    state.item = {};
    state.apiConnectionErr = {
      isError: false,
      status: null,
    };
  },
};

const actions = {
  async fetchItem(context, {token, id}) {
    try {
      const item = (await getApiItem('/customers', token, id));
      const orders = (await getApiList('/orders', token, {customer: id}));

      const result = {
        id: item.id,
        firstName: item.firstName,
        lastName: item.lastName,
        address: item.address,
        active: item.active ? 'TAK' : 'NIE',
        email: item.email,
        phone: item.phone,
        points: item.points,
        birthDate: item.birthDate,
        orders: orders.items.map(
          ({id, status, number, totalAmount, createdAt, billingAddress}) => ({
            id,
            status,
            number: `#${number}`,
            totalAmount: (totalAmount * 0.01).toFixed(2),
            date: moment(String(createdAt)).format('YYYY-MM-DD HH:mm'),
            customer: `${billingAddress.firstName} ${billingAddress.lastName}`,
          })
        ),
      };
      return context.commit('setItem', result);
    } catch (e) {
      return context.commit('setApiError', {isError: true, status: e});
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
