import { mapState, mapActions } from 'vuex'

const useState = {
  formStatus: '',
  alert: {
    status: '',
    message: ''
  }
}
const useMutations = {
  setItem(state, item) {
    state.item = {
      ...state.item,
      ...item
    }
  },
  setFormStatus(state, status){
    state.formStatus = status;
  },
  handleSubmitForm(state, { status, message, module = 'ProductTagViewModule'}) {
    state.alert.status = status
    state.alert.message = message;
  },
}
const useActions = {
  async fetchItem(context, cb) {
    try {
      context.commit('setFormStatus', 'sending');
      const data = await cb();
      context.commit('setItem', data)
      context.commit('setFormStatus', 'success');
    } catch (e) {
      console.error(e)
      context.commit('setFormStatus', 'danger');
      context.commit('handleSubmitForm', {
        status: 'danger',
        message: 'Coś poszło nie tak',
        module
      });
    }
  },
  async postItem(context, cb,  successMsg = 'Tag dodany pomyślnie') {
    try {
      context.commit('setFormStatus', 'sending');
      await cb();
      context.commit('setFormStatus', 'success');
      context.commit('handleSubmitForm', {
        status: 'success',
        message: successMsg,
        module
      });
    } catch (e) {
      console.error(e)
      context.commit('setFormStatus', 'danger');
      context.commit('handleSubmitForm', {
        status: 'danger',
        message: 'Coś poszło nie tak',
        module
      });
    }
  }
}

export default {
  useState,
  useMutations,
  useActions
}


export const useAsyncWrapper = (namespace) => ({
  computed: {
    ...mapState({
      formStatus: state => state[namespace].formStatus,
      alert: state => state[namespace].alert
    }),
    cApiSending() {
      return this.formStatus === 'sending'
    },
    cPromiseResolved() {
      return ['success', 'error'].includes(this.formStatus)
    },
    cAlertStatus() {
      const { status } = this.alert
      return status ? `alert-${status}` : ''
    },
    cAlertMessage() {
      return this.alert?.message ?? ''
    }
  },
  methods: {
    ...mapActions({
      handleFetchItem: (dispatch, payload) => dispatch(`${namespace}/handleFetchItem`, payload),
      handlePatchItem: (dispatch, payload) => dispatch(`${namespace}/handlePatchItem`, payload),
      handlePostItem: (dispatch, payload) => dispatch(`${namespace}/handlePostItem`, payload)
    })
  }
})
