import {LOGIN} from "@/api/backend";

const state = () => ({
  token: localStorage.getItem('user-token') || '',
  userDetails: JSON.parse(localStorage.getItem('user-details')) || null,
  status: '',
});

const mutations = {
  ['AUTH_REQUEST']: state => {
    state.status = 'loading';
  },
  ['AUTH_SUCCESS']: (state, payload) => {
    state.status = 'success';
    state.token = payload.token;
    state.userDetails = payload;
  },
  ['AUTH_ERROR']: state => {
    state.status = 'error';
  },
  ['AUTH_LOGOUT']: state => {
    state.token = '';
    localStorage.removeItem('user-token');
    localStorage.removeItem('user-details');
  }
};

const getters = {
  isAuthenticated: state => {
    if (state.userDetails) {
      const hasToken = !!state.token;
      const tokenExpires = new Date(state.userDetails.tokenExpiresAt);
      const isTokenDateValid = tokenExpires > new Date();

      return hasToken && isTokenDateValid;
    }
    return false;
  },
  isAdmin: state => {
    return state.userDetails && state.userDetails.roles.includes('ADMIN');
  },
  isOperator: state => {
    return state.userDetails && (state.userDetails.roles.includes('OPERATOR') || state.userDetails.roles.includes('ADMIN'));
  },
  isSEO: state => {
    return state.userDetails && (state.userDetails.roles.includes('SEO') || state.userDetails.roles.includes('OPERATOR'));
  },
  authUser: state => state.userDetails ?? null,
  authStatus: state => state.status,
  authToken: state => state.token,
};

const actions = {
  'AUTH_REQUEST': async ({commit}, user) => { // change this line to: 'AUTH_REQUEST': ({commit, dispatch}, user) => {
    try {
      commit('AUTH_REQUEST')
      const response = await LOGIN.post('', {username: user.username, password: user.password});
      localStorage.setItem('user-token', response.data.token);
      localStorage.setItem('user-details', JSON.stringify(response.data));
      commit('AUTH_SUCCESS', response.data);

      return response;
    } catch (e) {
      commit('AUTH_ERROR');
      console.error(e);
    }
  },
  'AUTH_LOGOUT': ({commit}) => {
    return new Promise(resolve => {
      commit('AUTH_LOGOUT')
      resolve('logged out')
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
