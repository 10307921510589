import { getApiReport } from "@/api/backend";
const FileSaver = require('file-saver');

const state = () => ({
  report: null
})

const mutations = {
  setReport(state, payload) {
    state.report = payload
  }
}

const actions = {
  async fetchReport(context, { token, dateTo, dateFrom, type = 'default' }) {
    try {
      if ( new Date(dateTo) < new Date(dateFrom) ) {
        throw new Error ('Data Do powinna być późniejszą datą niż Data Od');
      }
      const report = await getApiReport(`/reports?type=${type}&dateFrom=${dateFrom}&dateTo=${dateTo}`, token)

      FileSaver.saveAs(report, `report-${type}-${dateFrom}-${dateTo}.xlsx`)

    } catch (e) {
      throw new Error(e)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
