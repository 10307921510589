<template>
  <div class="tags-input__wrapper">
    <el-tag v-for="(tag, index) in dynamicTags"
            :key="index"
            size="small"
            :type="tagType"
            :closable="true"
            :close-transition="false"
            @close="handleClose(tag)"
    >
      {{ tag.label }}: {{ tag.value }}
    </el-tag>
  </div>
</template>

<script>
import { Tag } from 'element-ui';

export default {
  name: 'FilterTags',
  components: {
    [Tag.name]: Tag
  },
  props: {
    value: {
      type: Array,
      default: () => [],
      description: 'List of filter object {label, field, value}'
    },
    tagType: {
      type: String,
      default: 'primary',
      description: 'Tag type (primary|danger etc)'
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  data() {
    return {
      dynamicTags: [],
    };
  },
  methods: {
    handleClose(tag) {
      this.dynamicTags = this.dynamicTags.filter(item => !(item.field === tag.field && item.value === tag.value));
      this.$emit('change', this.dynamicTags);
    },
  },
  created() {
    this.$watch(
      'value',
      newVal => {
        this.dynamicTags = [...newVal];
      },
      { immediate: true }
    );
  },
};
</script>
