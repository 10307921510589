import {API} from "@/api/backend";
import {factoryMutations, factoryState} from "@/store/Modules/_factoryList";

const state = {
  ...factoryState,
  form: {
    active: false,
    discount: 0,
    name: '',
    tag: '',
    products: [],
    dateFrom: '',
    dateTo: ''
  },
  productList: [],
  tagList: [],
  apiStatus: '',
  apiMessage: ''
};

const mutations = {
  ...factoryMutations,
  updateValues(state, { field = '', values = [] }) {
    if ( field ) {
      state.form[field] = values
    }
  },
  resetForm(state, payload = {
    active: false,
    discount: 0,
    name: '',
    tag: '',
    products: [],
    dateFrom: new Date(),
    dateTo: new Date()
  }) {
    state.form = payload
  },
  updateList(state, { field = '', values = [] }) {
    if ( field ) {
      state[field] = values
    }
  },
  setApiMessage(state, message) {
    state.apiMessage = message
  },
  setApiStatus(state, status) {
    state.apiStatus = status;
  },
};

const actions = {
  async saveProductPromotionInfo({state, commit, rootState}, id = '') {
    const headers = {
      'Accept': 'application/ld+json',
      'Content-Type': 'application/ld+json',
      'Authorization': `Bearer ${rootState.Auth.token}`,
    }

    try {
      commit('setApiStatus', '')
      commit('setApiMessage', '')

      if ( id ) {
        await API.patch(`/product-promotions/${id}`, state.form, {
          headers: {
            ...headers,
            'Content-Type': 'application/merge-patch+json',
          }
        })
        commit('setApiStatus', 'success')
        commit('setApiMessage', 'Promocja zapisana pomyślnie')
        return
      }
      await API.post('/product-promotions', {
        ...state.form,
        // products: state.form.products[0],
        // tag: state.form.tag,
      }, {
        headers
      })
      commit('setApiStatus', 'success')
      commit('setApiMessage', 'Promocja dodana pomyślnie')
    } catch (e) {
      commit('setApiStatus', 'warning')
      commit('setApiMessage', e)
    }
  },

  async deleteProductPromotion({state, commit, rootState}, id) {
    const resp = await API.delete(`/product-promotions/${id}`, {
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/ld+json',
        'Authorization': `Bearer ${rootState.Auth.token}`,
      }
    })
    console.log(resp)
  },

  async fetchProductPromotionInfo({state, commit, rootState}, id) {
    const headers = {
      'Accept': 'application/ld+json',
      'Content-Type': 'application/ld+json',
      'Authorization': `Bearer ${rootState.Auth.token}`,
    }

    const resp = (await API.get(`/product-promotions/${id}`, { headers })).data
    commit('resetForm', {
      active: resp.active,
      discount: resp.discount,
      name: resp.name,
      tag: resp.tag,
      products: resp.products || [],
      dateFrom: resp.dateFrom,
      dateTo: resp.dateTo,
    })
    console.log(state.form)
  },

  async fetchProducts({commit}, query) {
    try {
      commit('setApiStatus', '')
      commit('setApiMessage', '')

      const handleQuery = query ? `&name=${query}` : ''
      const resp = ( await API.get(`/products?page=1&itemsPerPage=199&order%5Bname%5D=asc${handleQuery}`)).data['hydra:member']

      commit('updateList', {
        field: 'productList',
        values: resp.map( product => ({ value: product['@id'], label: `${product.name} (${product.ean})` }))
      })
    } catch (e) {
      commit('setApiStatus', 'warning')
      commit('setApiMessage', 'Wystąpił błąd podczas pobierania listy produktów')
    }
  },

  async fetchTags({commit}, query) {
    try {
      commit('setApiStatus', '')
      commit('setApiMessage', '')

      const resp = ( await API.get(`/product-tags?page=1&itemsPerPage=199`)).data['hydra:member']

      commit('updateList', {
        field: 'tagList',
        values: resp.map( tag => ({ value: tag['@id'], label: tag.name }))
      })
    } catch (e) {
      commit('setApiStatus', 'warning')
      commit('setApiMessage', 'Wystąpił błąd podczas pobierania listy tagów')
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
