import DashboardLayout from '@/views/Store/Layout/DashboardLayout';
import AuthLayout from '@/views/Store/Layout/AuthLayout';

// GeneralViews
import NotFound from '@/views/Store/Page/NotFound';

// Pages
const Home = () => import(/* webpackChunkName: "pages" */ '@/views/Store/Page/Home');
const Login = () => import(/* webpackChunkName: "pages" */ '@/views/Store/Page/Login');

// Dashboard page
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Store/Page/Dashboard');

// Views for API objects
const BundleList = () => import('@/views/Store/BundleList');
const BundleView = () => import('@/views/Store/Bundle/BundleView');
const CouponList = () => import('@/views/Store/CouponList');
const CouponAdd = () => import('@/views/Store/Coupon/CouponAdd');
const CouponView = () => import('@/views/Store/Coupon/CouponView');
const CustomerList = () => import('@/views/Store/CustomerList');
const CustomerView = () => import('@/views/Store/Customer/CustomerView');
const GiftList = () => import('@/views/Store/GiftList');
const GiftAdd = () => import('@/views/Store/Gift/GiftAdd');
const OperatorList = () => import('@/views/Store/OperatorList');
const OperatorAdd = () => import('@/views/Store/Operator/OperatorAdd');
const OperatorView = () => import('@/views/Store/Operator/OperatorView');
const OrderList = () => import('@/views/Store/OrderList');
const OrderView = () => import('@/views/Store/Order/OrderView');
const PartnerList = () => import('@/views/Store/PartnerList');
const PartnerAdd = () => import('@/views/Store/Partner/PartnerAdd');
const PartnerView = () => import('@/views/Store/Partner/PartnerView');

const ProductList = () => import('@/views/Store/ProductList');
const ProductAdd = () => import('@/views/Store/Product/ProductAddOld');
const ProductView = () => import('@/views/Store/Product/ProductView');
const ProductCategoryList = () => import('@/views/Store/ProductCategoryList');
const ProductCategoryView = () => import('@/views/Store/ProductCategory/ProductCategoryView');
const ProductLineList = () => import('@/views/Store/ProductLineList');
const ProductLineAdd = () => import('@/views/Store/ProductLine/ProductLineManage');
const ProductLineView = () => import('@/views/Store/ProductLine/ProductLineManage');
const ProductPropertyList = () => import('@/views/Store/ProductPropertyList');
const ProductPropertyAdd = () => import('@/views/Store/ProductProperty/ProductPropertyAdd');
const ProductPropertyView = () => import('@/views/Store/ProductProperty/ProductPropertyView');
const ProductTagList = () => import('@/views/Store/ProductTagList');
const ProductTagAdd = () => import('@/views/Store/ProductTag/ProductTagAdd');
const ProductTagView = () => import('@/views/Store/ProductTag/ProductTagView');
const ProductTimeDiscountList = () => import('@/views/Store/ProductTimeDiscountList');
const ProductTimeDiscountAdd = () => import('@/views/Store/ProductTimeDiscount/ProductTimeDiscountAdd');
const ProductTimeDiscountView = () => import('@/views/Store/ProductTimeDiscount/ProductTimeDiscountView');
const ProductPromotionList = () => import('@/views/Store/ProductPromotionList.vue');
const ProductPromotionAdd = () => import('@/views/Store/ProductPromotion/ProductPromotionAdd.vue');
const ServiceList = () => import('@/views/Store/ServiceList');
const ServiceView = () => import('@/views/Store/Service/ServiceAdd');
const NewsletterList = () => import('@/views/Store/NewsletterList');
const NewsletterAdd = () => import('@/views/Store/Newsletter/NewsletterAdd');
const NewsletterView = () => import('@/views/Store/Newsletter/NewsletterView');



const Report = () => import('@/views/Store/Report');
const UserProfile = () => import('@/views/Store/UserProfile');

const CartPromotionList = () => import('@/views/Store/Cart/PromotionsList.vue');
const CartPromotionView = () => import('@/views/Store/Cart/PromotionView.vue');

import store from '@/store';
import ProductCategoryAdd from "@/views/Store/ProductCategory/ProductCategoryAdd";
import ProductSampleList from "@/views/Store/ProductSampleList";

const isAuthenticated = (to, from, next) => {
  if (store.getters["Auth/isAuthenticated"]) {
    return next();
  }
  next("/login");
};
const isAdmin = (to, from, next) => {
  if (store.getters["Auth/isAuthenticated"] && store.getters["Auth/isAdmin"]) {
    return next();
  }
  next("/login");
};
const isOperator = (to, from, next) => {
  if (store.getters["Auth/isAuthenticated"] && store.getters["Auth/isOperator"]) {
    return next();
  }
  next("/login");
};
const isSEO = (to, from, next) => {
  if (store.getters["Auth/isAuthenticated"] && store.getters["Auth/isSEO"]) {
    return next();
  }
  next("/login");
};

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'Dashboards',
    component: DashboardLayout,
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        beforeEnter: isAuthenticated,
      },
    ],
  },

  {
    path: '/store',
    redirect: '/products',
    name: 'store',
    component: DashboardLayout,
    children: [
      {
        name: 'product-list',
        path: '/products/:pageNum?',
        component: ProductList,
        beforeEnter: isAuthenticated,
      },
      {
        name: 'product-add',
        path: '/product/add',
        component: ProductAdd,
        beforeEnter: isOperator,
      },
      {
        name: 'product-view',
        path: '/product/:id',
        component: ProductView,
        beforeEnter: isAuthenticated,
      },
      {
        name: 'product-category-list',
        path: '/product-categories/:pageNum?',
        component: ProductCategoryList,
        beforeEnter: isAuthenticated,
      },
      {
        name: 'product-category-add',
        path: '/product-category/add',
        component: ProductCategoryView,
        beforeEnter: isAuthenticated,
      },
      {
        name: 'product-category-view',
        path: '/product-category/:id',
        component: ProductCategoryView,
        beforeEnter: isAuthenticated,
      },
      {
        name: 'product-line-list',
        path: '/product-lines/:pageNum?',
        component: ProductLineList,
        beforeEnter: isAuthenticated,
      },
      {
        name: 'product-line-add',
        path: '/product-line/add',
        component: ProductLineAdd,
        beforeEnter: isAuthenticated,
      },
      {
        name: 'product-line-view',
        path: '/product-line/:id',
        component: ProductLineView,
        beforeEnter: isAuthenticated,
      },
      {
        name: 'product-property-list',
        path: '/product-properties/:pageNum?',
        component: ProductPropertyList,
        beforeEnter: isAuthenticated,
      },
      {
        name: 'product-property-add',
        path: '/product-property/add',
        component: ProductPropertyAdd,
        beforeEnter: isAuthenticated,
      },
      {
        name: 'product-property-view',
        path: '/product-property/:id',
        component: ProductPropertyView,
        beforeEnter: isAuthenticated,
      },
      {
        name: 'product-tag-list',
        path: '/product-tags/:pageNum?',
        component: ProductTagList,
        beforeEnter: isAuthenticated,
      },
      {
        name: 'product-tag-add',
        path: '/product-tag/add',
        component: ProductTagAdd,
        beforeEnter: isAuthenticated,
      },
      {
        name: 'product-tag-view',
        path: '/product-tag/:id',
        component: ProductTagAdd,
        beforeEnter: isAuthenticated,
      },
      {
        name: 'product-sample-list',
        path: '/product-samples/:pageNum?',
        component: ProductSampleList,
        beforeEnter: isAuthenticated,
      },
      {
        name: 'product-sample-manage',
        path: '/product-samples/:id',
        component: ProductSampleList,
        beforeEnter: isAuthenticated,
      },
      {
        name: 'seo-product-list',
        path: '/seo-products/:pageNum?',
        component: ProductList,
        beforeEnter: isSEO,
      },
      {
        name: 'seo-product-category-list',
        path: '/seo-product-categories/:pageNum?',
        component: ProductCategoryList,
        beforeEnter: isSEO,
      },
      {
        name: 'service-list',
        path: '/services/:pageNum?',
        component: ServiceList,
        beforeEnter: isAuthenticated,
      },
      {
        name: 'service-add',
        path: '/service/add',
        component: ServiceView,
        beforeEnter: isAuthenticated,
      },
      {
        name: 'service-view',
        path: '/service/:id',
        component: ServiceView,
        beforeEnter: isAuthenticated,
      },
      {
        name: 'newsletter',
        path: '/newsletter/:pageNum?',
        component: NewsletterList,
        beforeEnter: isAuthenticated,
      },
      {
        name: 'newsletter-add',
        path: '/newsletter/add',
        component: NewsletterAdd,
        beforeEnter: isOperator,
      },
      {
        name: 'newsletter-view',
        path: '/newsletter/:id',
        component: NewsletterView,
        beforeEnter: isAuthenticated,
      },
    ],
  },

  {
    path: '/customer-orders',
    redirect: '/orders',
    name: 'orders',
    component: DashboardLayout,
    children: [
      {
        name: 'order-list',
        path: '/orders/:pageNum?',
        component: OrderList,
        beforeEnter: isAuthenticated,
      },
      {
        name: 'order-view',
        path: '/order/:id',
        component: OrderView,
        beforeEnter: isAuthenticated,
      },
      {
        name: 'bundle-list',
        path: '/bundles/:pageNum?',
        component: BundleList,
        beforeEnter: isAuthenticated,
      },
      {
        name: 'bundle-view',
        path: '/bundle/:id',
        component: BundleView,
        beforeEnter: isAuthenticated,
      },
    ],
  },

  {
    path: '/reports',
    name: 'report',
    component: DashboardLayout,
    children: [
      {
        path: '/reports',
        name: 'report',
        component: Report,
        beforeEnter: isOperator,
      },
    ]
  },

  {
    path: '/clients',
    redirect: '/customers',
    name: 'clients',
    component: DashboardLayout,
    children: [
      {
        name: 'customer-list',
        path: '/customers/:pageNum?',
        component: CustomerList,
        beforeEnter: isAuthenticated,
      },
      {
        name: 'customer-view',
        path: '/customer/:id',
        component: CustomerView,
        beforeEnter: isAuthenticated,
      },
      {
        name: 'partner-list',
        path: '/partners/:pageNum?',
        component: PartnerList,
        beforeEnter: isAuthenticated,
      },
      {
        name: 'partner-add',
        path: '/partner/ass',
        component: PartnerAdd,
        beforeEnter: isOperator,
      },
      {
        name: 'partner-view',
        path: '/partner/:id',
        component: PartnerView,
        beforeEnter: isOperator,
      },
    ],
  },

  {
    path: '/admin',
    redirect: '/operators',
    name: 'administration',
    component: DashboardLayout,
    children: [
      {
        name: 'operator-list',
        path: '/operators/:pageNum?',
        component: OperatorList,
        beforeEnter: isAdmin,
      },
      {
        name: 'operator-add',
        path: '/operator/add',
        component: OperatorAdd,
        beforeEnter: isAdmin,
      },
      {
        name: 'operator-view',
        path: '/operator/:id',
        component: OperatorView,
        beforeEnter: isAdmin,
      },
      {
        name: 'coupon-list',
        path: '/coupons/:pageNum?',
        component: CouponList,
        beforeEnter: isAdmin,
      },
      {
        name: 'coupon-add',
        path: '/coupon/add',
        component: CouponAdd,
        beforeEnter: isAdmin,
      },
      {
        name: 'coupon-view',
        path: '/coupon/:id',
        component: CouponView,
        beforeEnter: isAdmin,
      },
      {
        name: 'product-time-discount-list',
        path: '/product-time-discounts/:pageNum?',
        component: ProductTimeDiscountList,
        beforeEnter: isOperator,
      },
      {
        name: 'product-time-discount-add',
        path: '/product-time-discount/add',
        component: ProductTimeDiscountAdd,
        beforeEnter: isOperator,
      },
      {
        name: 'product-time-discount-view',
        path: '/product-time-discount/:id',
        component: ProductTimeDiscountView,
        beforeEnter: isOperator,
      },
      {
        name: 'product-promotion-list',
        path: '/product-promotions/:pageNum?',
        component: ProductPromotionList,
        beforeEnter: isOperator,
      },
      {
        name: 'product-promotion-add',
        path: '/product-promotion/add',
        component: ProductPromotionAdd,
        beforeEnter: isOperator,
      },
      {
        name: 'product-promotion-view',
        path: '/product-promotion/:id',
        component: ProductPromotionAdd,
        beforeEnter: isOperator,
      },
      {
        name: 'cart-promotions',
        path: '/cart-promotions/:pageNum?',
        component: CartPromotionList,
        beforeEnter: isAuthenticated,
      },
      {
        name: 'cart-promotion-add',
        path: '/cart-promotion-add',
        component: CartPromotionView,
        beforeEnter: isOperator,
      },
      {
        name: 'cart-promotion-manage',
        path: '/cart-promotion/:id',
        component: CartPromotionView,
        beforeEnter: isOperator,
      },
      {
        name: 'gift-list',
        path: '/gifts/:pageNum?',
        component: GiftList,
        beforeEnter: isOperator,
      },
      {
        name: 'gift-add',
        path: '/gift/manage',
        component: GiftAdd,
        beforeEnter: isOperator,
      },
      {
        name: 'gift-manage',
        path: '/gift/manage?id=:id',
        component: GiftAdd,
        beforeEnter: isOperator,
      },
    ],
  },
  {
    name: 'cart-promotions',
    path: '/cart-promotions',
    component: CartPromotionList,
    beforeEnter: isAuthenticated,
  },

  {
    path: '/user',
    redirect: '/user/profile',
    name: 'user',
    component: DashboardLayout,
    children: [
      {
        name: 'profile',
        path: '/user/profile',
        component: UserProfile,
        beforeEnter: isAuthenticated,
      },
    ],
  },

  {
    path: '/',
    component: AuthLayout,
    name: 'Authentication',
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home,
        meta: {
          noBodyBackground: true
        }
      },
      {
        path: '/login',
        name: 'Login',
        component: Login,
      },
      {path: '*', component: NotFound},
    ],
  },
];

export default routes;
