import { API } from "@/api/backend";

export default {
  async useFetch({ state, rootState, commit }, { method = 'get', url, payload }) {
    try {
      let resp = null
      const config = {
        headers: {
          Authorization: `Bearer ${rootState.Auth.token}`
        }
      }
      const alerts = {
        get: {
          type: null,
          message: null
        },
        post: {
          type: 'success',
          message: 'Utworzono poprawnie'
        },
        put: {
          type: 'success',
          message: 'Zaktualizowano poprawnie'
        },
        delete: {
          type: 'success',
          message: 'Usunięto poprawnie'
        }
      }
      state.loading = true;
      if ( ['get', 'delete'].includes(method) ) {
        resp = await API[method](url, config)
      } else {
        resp = await API[method](url, payload, config)
      }
      commit('updateState', {
        field: 'loading',
        value: false
      })
      state.alertType = alerts[method].type
      state.alertMessage = alerts[method].message

      return resp
    } catch (e) {
      console.error('test', e)
      state.alertType = 'danger'
      if ( e.response ) {
        const { status, statusText } = e.response
        state.alertMessage = `${status} ${statusText}`
      } else {
        state.alertMessage = e.message;
      }
      state.loading = false;
      return []
    }
  }
}
