import {getApiList, getIdFromIri} from "@/api/backend";
import {factoryGetters, factoryMutations, factoryState} from "@/store/Modules/_factoryList";

const state = JSON.parse(JSON.stringify(factoryState));

const mutations = {
  ...factoryMutations,
};

const getters = {
  ...factoryGetters,
};

const actions = {
  async fetchList(context, {token, page}) {
    try {
      const filters = context.getters.getFilters;
      const list = await getApiList('/product-categories', token, {...filters, page, itemsPerPage: 199 });

      const result = list.items.map(({id, name, active, position, discount, slug}) => ({
        id,
        name,
        active: active ? 'TAK' : 'NIE',
        position,
        slug,
        discount,
      }));

      return context.commit('setItems', {items: result, totalItems: list.totalItems});
    } catch (e) {
      return context.commit('setApiError', {isError: true, status: e});
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
