/**
 * Zwraca odmianę rzeczownika w języku polskim
 * @param number liczebnik
 * @param nouns rzeczowniki z którymi łączą się liczebniki np. [kot, koty, kotów]
 * @returns String
 */
export const polishNumerals = (number, nouns = []) => {
  if (number === 1) return nouns[0].replace('{num}', number);
  const n100 = number % 100;
  if ((n100 < 5) || (n100 > 21 && [2, 3, 4].includes(n100 % 10))) return nouns[1].replace('{num}', number);
  return nouns[2].replace('{num}', number);
};
