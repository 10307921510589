
export const factoryState = {
  items: [],
  filterValues: [],
  totalItems: 0,
  apiConnectionErr: {
    isError: false,
    status: null,
  },
};

export const factoryMutations = {
  setItems(state, {items, totalItems}) {
    state.items = items;
    state.totalItems = totalItems;
  },
  resetItems(state) {
    state.items = [];
    state.totalItems = 0;
    state.apiConnectionErr = {
      isError: false,
      status: null,
    };
  },
  setFilterValues(state, values) {
    state.filterValues = values;
  },
  setApiError(state, hasError) {
    state.apiConnectionErr = hasError;
  },
};

export const factoryGetters = {
  getFilters: state => {
    return state.filterValues.reduce((obj, item) => (obj[item.field] = item.value, obj) ,{});
  },
};
