import {API, XHR_REQUEST} from "@/api/backend";

const _defaultCouponInformation = {
  code:'',
  minGrossPrice: '100',
  discount: 0,
  name: '',
  active: true,
  available: true,
  qty: 2,
  dateFrom: new Date(),
  dateTo: new Date()
}

const state = {
  couponInformation: { ..._defaultCouponInformation },
  apiStatus: null,
  postStatus: null,
  errorMessage: '',
}

const mutations = {
  updateCouponField(state, { field, value }) {
    try {
      if ( value instanceof  Date ) {
        state.couponInformation[field] = value;
        return;
      }
      switch ( typeof value ) {
        case 'object' : console.log(value); state.couponInformation[field] = { ...state.couponInformation[field], ...value }; break;
        default: state.couponInformation[field] = value; break;
      }
    } catch (e) {
      console.error(e)
      return false;
    }
  },
  setApiStatus(state, status) {
    state.apiStatus = status;
  },
  setPostStatus(state, status) {
    state.postStatus = status;
  },
  setErrorMessage(state, message) {
    state.errorMessage = message
  },
  setCouponInformation(state, {couponInformation, apiStatus}) {
    state.couponInformation = couponInformation;
    state.apiStatus = apiStatus;
  },
  resetCategoryInfo(state) {
    state.couponInformation = { ..._defaultCouponInformation };
    state.apiStatus = null;
    state.postStatus = null;
  },
}

const actions = {
  async fetchCouponInformation({commit, state, rootState}, categoryId ){
    commit('setPostStatus', 'loading');
    commit('setErrorMessage','')
    try {
      commit('setApiStatus', 'loading');
      const resp = (await API.get(`/coupons/${categoryId}`, {
        headers: {
          Authorization: `Bearer ${rootState.Auth.token}`
        }
      }));
      commit('setCouponInformation', {
        couponInformation: resp.data,
        apiStatus: 'success'
      })
    } catch (e) {
      commit('setCouponInformation', { couponInformation: {}, apiStatus: 'error' });
    }
  },
  async submitForm({commit, rootState}, {id, method}) {
    commit('setPostStatus', 'loading');
    commit('setErrorMessage','')
    try {
      console.log(method)
      await API[method](`/coupons${id ? '/' + id : ''}`, {
        ...state.couponInformation,
          minGrossPrice: method === 'put' ? JSON.stringify(state.couponInformation.minGrossPrice) : state.couponInformation.minGrossPrice,
        description: {
          long: '',
          short: '',
          ...state.couponInformation.description
        },
        carts: []
      }, {
        headers: {
          'Authorization': `Bearer ${rootState.Auth.token}`
        }
      })
      commit('setPostStatus', 'success');
      return true
    } catch (e) {
      commit('setPostStatus', 'error');
      commit('setErrorMessage', e.response.data['hydra:description'])
    }
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
