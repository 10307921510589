import {getApiList} from "@/api/backend";
import {factoryMutations, factoryState} from "@/store/Modules/_factoryList";
import moment from "moment";

const state = {
  ...factoryState,
};

const mutations = {
  ...factoryMutations,
};

const actions = {
  async fetchList(context, {token, page}) {
    try {
      const list = (await getApiList('/cart-promotions', token, {page}));
      const result = list.items.map(
        ({id, name, active, dateFrom, dateTo}) => ({
          id,
          name,
          active: active ? 'TAK' : 'NIE',
          dateFrom: moment(String(dateFrom)).format('YYYY-MM-DD HH:mm'),
          dateTo: moment(String(dateTo)).format('YYYY-MM-DD HH:mm'),
        })
      );

      return context.commit('setItems', {items: result, totalItems: list.totalItems});
    } catch ({response}) {
      return context.commit('setApiError', {isError: true, status: response?.status});
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
