<template>
  <side-bar :logo="logo">
    <template slot="links">

      <sidebar-item :link="{ name: 'Dashboard', icon: 'ni ni-tv-2 text-primary', path: '/dashboard' }">
      </sidebar-item>

      <sidebar-item :link="{ name: 'Sklep', icon: 'ni ni-shop text-primary' }" v-if="isOperator">
        <sidebar-item :link="{ name: 'Produkty', path: '/products' }"></sidebar-item>
        <sidebar-item :link="{ name: 'Kategorie produktów', path: '/product-categories' }"></sidebar-item>
        <sidebar-item :link="{ name: 'Linie produktowe', path: '/product-lines' }"></sidebar-item>
        <sidebar-item :link="{ name: 'Atrybuty', path: '/product-properties' }"></sidebar-item>
        <sidebar-item :link="{ name: 'Tagi', path: '/product-tags' }"></sidebar-item>
        <sidebar-item :link="{ name: 'Próbki', path: '/product-samples' }"></sidebar-item>
        <sidebar-item :link="{ name: 'Usługi', path: '/services' }"></sidebar-item>
      </sidebar-item>

      <sidebar-item :link="{ name: 'Zamówienia', icon: 'ni ni-cart text-primary' }" v-if="isOperator">
        <sidebar-item
          :link="{ name: 'Detaliczne', icon: 'ni ni-cart text-primary', path: '/orders' }"></sidebar-item>
        <sidebar-item :link="{ name: 'Hurtowe', icon: 'ni ni-cart text-primary', path: '/bundles' }"></sidebar-item>
      </sidebar-item>

      <sidebar-item :link="{ name: 'Raporty', icon: 'ni ni-collection text-primary', path: '/reports'}" v-if="isOperator">
      </sidebar-item>

      <sidebar-item :link="{ name: 'SEO', icon: 'ni ni-single-02 text-green' }" v-if="isSEO">
        <sidebar-item :link="{ name: 'Produkty', path: '/seo-products' }"></sidebar-item>
        <sidebar-item :link="{ name: 'Kategorie produktów', path: '/seo-product-categories' }"></sidebar-item>
      </sidebar-item>

      <sidebar-item :link="{ name: 'Marketing', icon: 'ni ni-email-83 text-green' }" v-if="isSEO">
        <sidebar-item :link="{ name: 'Newsletter', path: '/newsletter' }"></sidebar-item>
      </sidebar-item>

      <sidebar-item :link="{ name: 'Klienci', icon: 'ni ni-single-02 text-primary' }" v-if="isOperator">
        <sidebar-item :link="{ name: 'Klienci detaliczni', path: '/customers' }"></sidebar-item>
        <sidebar-item :link="{ name: 'Partnerzy', path: '/partners' }"></sidebar-item>
      </sidebar-item>

      <sidebar-item :link="{ name: 'Promocje', icon: 'ni ni-tag text-red'}" v-if="isOperator">
        <sidebar-item :link="{ name: 'Kody promocyjne', path: '/coupons' }"></sidebar-item>
        <sidebar-item :link="{ name: 'Promocje czasowe', path: '/product-time-discounts' }"></sidebar-item>
        <sidebar-item :link="{ name: 'Promocje produktowe', path: '/product-promotions' }"></sidebar-item>
        <sidebar-item :link="{ name: 'Promocje od progu transakcji', path: '/cart-promotions' }"></sidebar-item>
        <sidebar-item :link="{ name: 'Prezenty', path: '/gifts' }"></sidebar-item>
      </sidebar-item>

      <sidebar-item :link="{ name: 'Administracja', icon: 'ni ni-hat-3 text-red' }" v-if="isAdmin">
        <sidebar-item :link="{ name: 'Operatorzy', path: '/operators' }"></sidebar-item>
      </sidebar-item>

    </template>
  </side-bar>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapGetters} = createNamespacedHelpers('Auth')

export default {
  name: 'DashboardSidebar',
  data() {
    return {
      logo: "/img/brand/brand.png",
    };
  },
  computed: {
    ...mapGetters([
      'isAdmin',
      'isOperator',
      'isSEO',
    ]),
  },
};
</script>
