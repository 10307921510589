import {getApiList, updateApiItem} from "@/api/backend";
import {factoryMutations, factoryState} from "@/store/Modules/_factoryList";

const state = JSON.parse(JSON.stringify(factoryState));

const mutations = {
  ...factoryMutations,
};

const actions = {
  async fetchList(context, {token, page}) {
    try {
      const list = (await getApiList('/product-properties', token, {page}));

      const result = list.items.map(
        ({ id, name, code }) => ({
          id,
          name,
          code,
        })
      );

      return context.commit('setItems', {items: result, totalItems: list.totalItems});
    } catch (e) {
      return context.commit('setApiError', {isError: true, status: e});
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
