<template>
  <div class="row align-items-center py-4">
    <div class="col-lg-6 col-7">
      <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-2">
        <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
          <li class="breadcrumb-item"><router-link to="/"><i class="fas fa-home"></i></router-link></li>
          <li v-for="(item, index) in items" :key="index" class="breadcrumb-item">
            <router-link :to="item.link" v-if="item.link">{{ item.title }}</router-link>
            <template v-else>{{ item.title }}</template>
          </li>
        </ol>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BreadcrumbHeader',
  props: {
    items: {
      type: Array,
      default: () => [],
    }
  },
};
</script>
