import {API} from "@/api/backend";
import {factoryMutations, factoryState} from "@/store/Modules/_factoryList";

const state = {
  ...factoryState,
  form: {
    active: false,
    minGrossPrice: '1000',
    giftGrossPrice: '0',
    name: '',
    giftProduct: '',
    categories: [],
    lines: [],
    products: [],
    dateFrom: '',
    dateTo: ''
  },
  productList: [],
  categoryList: [],
  linesList: [],
  apiStatus: '',
  apiMessage: ''
};

const mutations = {
  ...factoryMutations,
  updateValues(state, { field = '', values = [] }) {
    if ( field ) {
      state.form[field] = values
    }
  },
  resetForm(state, payload = {
    active: false,
    minGrossPrice: '',
    giftGrossPrice: '',
    name: '',
    giftProduct: '',
    categories: [],
    lines: [],
    products: [],
    dateFrom: new Date(),
    dateTo: new Date()
  }) {
    state.form = payload
  },
  updateList(state, { field = '', values = [] }) {
    if ( field ) {
      state[field] = values
    }
  },
  setApiMessage(state, message) {
    state.apiMessage = message
  },
  setApiStatus(state, status) {
    state.apiStatus = status;
  },
};

const actions = {
  async saveGiftInfo({state, commit, rootState}, id = '') {
    const headers = {
      'Accept': 'application/ld+json',
      'Content-Type': 'application/ld+json',
      'Authorization': `Bearer ${rootState.Auth.token}`,
    }

    try {
      commit('setApiStatus', '')
      commit('setApiMessage', '')

      if ( id ) {
        await API.patch(`/gifts/${id}`, state.form, {
          headers: {
            ...headers,
            'Content-Type': 'application/merge-patch+json',
          }
        })
        commit('setApiStatus', 'success')
        commit('setApiMessage', 'Gift zapisany pomyślnie')
        return
      }
      await API.post('/gifts', {
        ...state.form,
        product: state.form.products[0],
        products: state.form.products[0],
        categories: state.form.categories[0],
        category: state.form.categories[0],
        line: state.form.lines[0],
        lines: state.form.lines[0]
      }, {
        headers
      })
      commit('setApiStatus', 'success')
      commit('setApiMessage', 'Gift dodany pomyślnie')

    } catch (e) {
      commit('setApiStatus', 'warning')
      commit('setApiMessage', e)
    }
  },

  async deleteGift({state, commit, rootState}, id) {
    const resp = await API.delete(`/gifts/${id}`, {
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/ld+json',
        'Authorization': `Bearer ${rootState.Auth.token}`,
      }
    })
    console.log(resp)
  },

  async fetchGiftInfo({state, commit, rootState}, id ) {
    const headers = {
      'Accept': 'application/ld+json',
      'Content-Type': 'application/ld+json',
      'Authorization': `Bearer ${rootState.Auth.token}`,
    }

    const resp = (await API.get(`/gifts/${id}`, { headers })).data
    commit('resetForm', {
      active: resp.active,
      invoiced: resp.invoiced,
      minGrossPrice: resp.minGrossPrice.toString(),
      giftGrossPrice: resp.giftGrossPrice.toString(),
      name: resp.name,
      giftProduct: resp.giftProduct,
      categories: resp.categories || [],
      lines: resp.lines || [],
      products: resp.products || [],
      dateFrom: resp.dateFrom,
      dateTo: resp.dateTo,
    })
    console.log(state.form)
  },

  async fetchProducts({commit}, query) {
    try {
      commit('setApiStatus', '')
      commit('setApiMessage', '')

      const handleQuery = query ? `&name=${query}` : ''
      const resp = ( await API.get(`/products?page=1&itemsPerPage=199${handleQuery}`)).data['hydra:member']

      commit('updateList', {
        field: 'productList',
        values: resp.map( product => ({ value: product['@id'], label: product.name }))
      })
    } catch (e) {
      commit('setApiStatus', 'warning')
      commit('setApiMessage', 'Wystąpił błąd poczas pobierania listy produktów')
    }
  },

  async fetchCategories({commit}, query) {
    try {
      commit('setApiStatus', '')
      commit('setApiMessage', '')

      const resp = ( await API.get(`/product-categories?page=1&itemsPerPage=199`)).data['hydra:member']

      commit('updateList', {
        field: 'categoryList',
        values: resp.map( category => ({ value: category['@id'], label: category.name }))
      })
    } catch (e) {
      commit('setApiStatus', 'warning')
      commit('setApiMessage', 'Wystąpił błąd poczas pobierania listy kategorii')
    }
  },

  async fetchLines({commit}, query) {
    try {
      commit('setApiStatus', '')
      commit('setApiMessage', '')

      const resp = ( await API.get(`/product-lines?page=1&itemsPerPage=199`)).data['hydra:member']

      commit('updateList', {
        field: 'linesList',
        values: resp.map( line => ({ value: line['@id'], label: line.name }))
      })
    } catch (e) {
      commit('setApiStatus', 'warning')
      commit('setApiMessage', 'Wystąpił błąd poczas pobierania listy linii')
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
