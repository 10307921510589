import {getApiList} from "@/api/backend";
import {factoryGetters, factoryMutations, factoryState} from "@/store/Modules/_factoryList";
import moment from "moment";

const state = JSON.parse(JSON.stringify(factoryState));

const mutations = {
  ...factoryMutations,
};

const getters = {
  ...factoryGetters,
};

const actions = {
  async fetchList(context, {token, page}) {
    try {
      const filters = context.getters.getFilters;
      const list = (await getApiList('/bundles', token, {...filters, page}));

      const result = list.items.map(
        ({id, status, number, totalAmount, createdAt, billingAddress}) => ({
          id,
          status,
          number: `#${number}`,
          total: (totalAmount * 0.01).toFixed(2),
          date: moment(String(createdAt)).format('YYYY-MM-DD HH:mm'),
          partner: `${billingAddress.company}`,
        })
      );

      return context.commit('setItems', {items: result, totalItems: list.totalItems});
    } catch ({response}) {
      return context.commit('setApiError', {isError: true, status: response?.status});
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
