import {getApiItem, postApiItem, updateApiItem} from "@/api/backend";
import useAsyncStore from '@/composables/useAsyncWrapper'

const { useState, useActions, useMutations } = useAsyncStore
const { postItem, patchItem, fetchItem } = useActions

const initialState = {
  item: {
    id: null,
    active: false,
    amount: 0,
    ean: '',
    name: '',
    description: {
      short: '',
      long: '',
    }
  }
}

const state = {
  ...useState,
  ...initialState
}

const mutations = {
  ...useMutations
}

const actions = {
  resetForm(context) {
    context.commit('setItem', initialState.item)
  },
  async handleFetchItem(context, { token, id }) {
    await fetchItem(context, async () => await getApiItem(`/services`, token, id) )
  },
  async handlePostItem(context, { token, data}) {
    await postItem(
      context,
      async () => await postApiItem('/services', token, data),
      'Usługa dodana pomyślnie'
    )
  },
  async handlePatchItem(context, { token, id, data }) {
    await postItem(
      context,
      async () => await updateApiItem(`/services/${id}`, token, data),
      'Usługa zaktualizowana pomyślnie'
    )
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
