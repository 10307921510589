<template>
  <div class="wrapper">
    <notifications></notifications>
    <dashboard-sidebar></dashboard-sidebar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <dashboard-footer v-if="!$route.meta.hideFooter"></dashboard-footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardFooter from '@/views/Store/Layout/DashboardFooter';
import DashboardNavbar from '@/views/Store/Layout/DashboardNavbar';
import DashboardSidebar from "@/views/Store/Layout/DashboardSidebar";
import {FadeTransition} from 'vue2-transitions';

export default {
  components: {
    DashboardFooter,
    DashboardNavbar,
    DashboardSidebar,
    FadeTransition,
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        initScrollbar('sidenav');
      }
    }
  },
  mounted() {
    this.initScrollbar()
  }
};
</script>
