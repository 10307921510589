import {getApiList, getIdFromIri} from "@/api/backend";
import {factoryGetters, factoryMutations, factoryState} from "@/store/Modules/_factoryList";

const state = JSON.parse(JSON.stringify(factoryState));

const mutations = {
  ...factoryMutations,
};

const getters = {
  ...factoryGetters,
};

const actions = {
  async fetchList(context, {token, page}) {
    try {
      const filters = context.getters.getFilters;
      const list = (await getApiList('/products', token, {...filters, page}));

      const categoryIds = [];
      list.items.forEach(({category}) => categoryIds.push(getIdFromIri(category)));
      const categories = await getApiList('/product-categories', token, {id: categoryIds});

      const result = list.items.map(
        ({id, name, ean, category, active, grossPrice}) => ({
          id,
          name,
          ean: ean || 'Brak numeru EAN',
          active: active ? 'TAK' : 'NIE',
          price: (grossPrice * 0.01).toFixed(2),
          category: category ? categories.items.find((c) => c.id === getIdFromIri(category)).name : '-',
        })
      );

      return context.commit('setItems', {items: result, totalItems: list.totalItems});
    } catch (e) {
      return context.commit('setApiError', {isError: true, status: e});
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
