import {getApiItem, getApiList, getIdFromIri, updateApiItem, postApiItem, deleteApiItem } from "@/api/backend";

const state = {
  item: {
    id: '',
    ean: '',
    name: '',
    active: false,
    available: false,
    internal: false,
    archived: false,
    qty: 0,
    category: null,
    producer: {
      id: '891919db-1548-4277-801e-e43a6228bd8e',
      name: 'Clochee'
    },
    line: null,
    baseNetPrice: (100 * 0.01).toFixed(2),
    baseGrossPrice: (100 * 0.01).toFixed(2),
    taxRate: (100 * 0.01).toFixed(0),
    description: {
      short: '',
      long: ''
    },
    properties: {
      "skin_type":"",
      "application":"",
      "4_elements_for_whom":"",
      "fragrance_head":"",
      "fragrance_heart":"",
      "fragrance_base":"",
      "4_elements_product_type":"",
      "how_to_recycle":"",
      "4_elements_packaging":"",
      "ingredients_effect":"",
      "volume":"50 ml",
      "consumer_benefits":[],
      "4_elements_effects":"",
      "ingredients":[]
    },
    tags: [],
    recommendations: [],
    gallery: [],
    image: '',
    seo: {
      title: '',
      description: ''
    }
  },
  options: {},
  apiConnectionErr: {
    isError: false,
    status: null,
  },

};

const mutations = {
  setData(state, data = {}) {
    const {item, options} = data;
    state.item = item;
    state.options = options;
  },
  setImages(state, payload) {
    console.log(payload)
    state.item = {
      ...state.item,
      ...payload
    }
  },
  setApiError(state, hasError) {
    state.apiConnectionErr = hasError
  },
  resetData(state) {
    state.item = {
      id: '',
      name: '',
    };
    state.options = {};
    state.apiConnectionErr = {
      isError: false,
      status: null,
    };
  },
};

/*
{
    "id": "0713b4d0-cfe2-4ddb-acd3-0a71c9d19b4d",

    "name": "Soniczny Roller",
    "slug": "akcesoria/akcesoria-beauty/-/soniczny-roller",
    "description": {
        "short": "Czy może być coś skuteczniejszego niż soniczny roller, który generuje 6 tysięcy wibracji na minutę? Pięknie pobudzona, rozświetlona skóra jest w zasięgu Twojej ręki.",
        "long": "Wałeczek został wykonany z różowego kwarcu nazywanego w Azji \"kamienieniem miłości”. Kamień za pomocą chłodu zamyka pory, wspomaga drenaż limfy oraz krążenie krwi.\r\nMasażer ma działanie przeciwzmarszczkowe, zmniejsza opuchliznę oraz cienie pod oczami. Można go używać \"na sucho\", natomiast doskonale sprawdzi się również w duecie z serum. Masażer jest idealny do \"przygotowania\" naszej cery przed wielkim wyjściem, wspaniale odświeża, rozjaśnia i rozświetla naszą skórę, a bruzdy i zmarszczki są płytsze.\r\nBenefity:*Wyraźnie wyczuwalny lifting twarzy oraz poprawa jej owalu*Dotlenienie i odżywienie skóry dzięki poprawie krążenia*Dzięki 6000 sonicznym wibracjom/minutę potęguje efekt masażu*Z twoim ulubionym serum - czyni cuda!*Relaksuje i niweluje spięcia mięśni twarzy\r\n \r\nKwarc nazywany jest kamieniem miłości <3Baterie nie są dołączone do zestawu.Kraj pochodzenia kamienia: Brazylia\r\n \r\nProducent: Easy Livin'\r\nInformujemy, że rabaty i promocje specjalne nie obejmują akcesoriów beauty."
    },

    "ean": "5907796692240",
    "qty": 0,

    "active": false,
    "available": false,
    "internal": false,
    "archived": false,

    "fixedDiscount": -1,
    "discount": 0,
    "taxRate": 2300,
    "tax": "23%",

    "baseNetPrice": 11301,
    "baseGrossPrice": 13900,

    "netPrice": 11301,
    "grossPrice": 13900,

    "producer": "/api/v1/rest/producers/893934e6-e6eb-40e1-be9e-4a43a0ae66d6",
    "category": "/api/v1/rest/product-categories/42c39cdc-8942-4476-8a07-538b49e151dc",
    "properties": {
        "skin_type": "Dla wszystkich typów skóry."
    },
    "tags": [
        "/api/v1/rest/product-tags/e35970b0-54d2-4860-89d3-7da72e7ef747",
        "/api/v1/rest/product-tags/2ead3033-e070-4e35-8a88-01cd1b8413b1",
        "/api/v1/rest/product-tags/78bae7fd-e779-4cc2-bfef-d176f00c8601",
        "/api/v1/rest/product-tags/ecc030e3-713f-4c20-9c0d-268ba429786c"
    ],
    "recommendations": [
        "/api/v1/rest/products/40bddd24-a48e-4a8c-92f8-c5a33072cf45",
        "/api/v1/rest/products/4b699598-2af8-439e-a83a-d88ca05c0387",
        "/api/v1/rest/products/05ee0b60-9e2c-4924-9007-ad47c25cbe14"
    ],

    "image": {
        "media": "picture",
        "url": "https://clocheestore.blob.core.windows.net/local-home/a8.webp",
        "thumbUrl": "https://clocheestore.blob.core.windows.net/local-home/a8_th.webp"
    }
    "gallery": [],
}
*/
const actions = {
  async addItem(context, {token}) {
    try {
      const categoryList = (await getApiList('/product-categories', token, {itemsPerPage: 1000}));
      const lineList = (await getApiList('/product-lines', token, {itemsPerPage: 1000}));
      const propertyList = (await getApiList('/product-properties', token, {itemsPerPage: 1000}));
      const productList = (await getApiList('/products', token, {itemsPerPage: 1000}));
      const tagList = (await getApiList('/product-tags', token, {itemsPerPage: 1000}));

      const result = {
        name: '',
        active: false,
        available: false,
        internal: false,
        archived: false,
        qty: 0,
        category: null,
        producer: {
          id: '',
          name: ''
        },
        line: null,
        baseNetPrice: (100 * 0.01).toFixed(2),
        baseGrossPrice: (100 * 0.01).toFixed(2),
        taxRate: (100 * 0.01).toFixed(0),
        description: {
          short: '',
          long: ''
        },
        properties:  {
          "skin_type":"",
          "application":"",
          "4_elements_for_whom":"",
          "fragrance_head":"",
          "fragrance_heart":"",
          "fragrance_base":"",
          "4_elements_product_type":"",
          "how_to_recycle":"",
          "4_elements_packaging":"",
          "ingredients_effect":"",
          "volume":"50 ml",
          "consumer_benefits":[],
          "4_elements_effects":"",
          "ingredients":[]
        },
        tags: [],
        recommendations: [],
        gallery: [],
        image: '',
        seo: {
          title: '',
          description: ''
        }
      };

      const categories = categoryList.items.map(
        ({id, name}) => ({
          id, name
        })
      );
      const lines = lineList.items.map(
        ({id, name}) => ({
          id, name
        })
      );
      const properties = propertyList.items.map(
        ({id, name, code}) => ({
          id, name, code,
        })
      );
      const recommendations = productList.items.map(
        ({id, name}) => ({
          id, name
        })
      );
      const tags = tagList.items.map(
        ({id, name}) => ({
          id, name
        })
      );

      return context.commit('setData', {item: result, options: {categories, lines, properties, tags, recommendations}});
    } catch (e) {
      return context.commit('setApiError', {isError: true, status: e});
    }
  },
  async fetchItem(context, {token, id}) {
    try {
      const item = (await getApiItem('/products', token, id));
      const producer = (await getApiItem('/producers', token, getIdFromIri(item.producer)));

      const categoryList = (await getApiList('/product-categories', token, {itemsPerPage: 1000}));
      const lineList = (await getApiList('/product-lines', token, {itemsPerPage: 1000}));
      const propertyList = (await getApiList('/product-properties', token, {itemsPerPage: 1000}));
      const productList = (await getApiList('/products', token, {itemsPerPage: 1000}));
      const tagList = (await getApiList('/product-tags', token, {itemsPerPage: 1000}));

      const result = {
        id: item.id,
        ean: item.ean,
        name: item.name,
        active: item.active,
        available: item.available,
        internal: item.internal,
        archived: item.archived,
        fixedDiscount: ((item.fixedDiscount ?? -1) < 0) ? null : (item.fixedDiscount * 0.01).toFixed(0),
        qty: item.qty,
        category: getIdFromIri(item.category),
        producer: {id: producer.id, name: producer.name},
        line: item.line?.id || null,
        baseNetPrice: (item.baseNetPrice * 0.01).toFixed(2),
        baseGrossPrice: (item.baseGrossPrice * 0.01).toFixed(2),
        taxRate: (item.taxRate * 0.01).toFixed(0),
        description: item.description,
        properties: item.properties,
        tags: item.tags.map(iri => getIdFromIri(iri)),
        recommendations: item.recommendations.map(iri => getIdFromIri(iri)),
        image: item.image,
        gallery: item.gallery,
        seo: item.seo || {
          title: '',
          description: ''
        }
      };

      const categories = categoryList.items.map(
        ({id, name}) => ({
          id, name
        })
      );
      const lines = lineList.items.map(
        ({id, name}) => ({
          id, name
        })
      );
      const properties = propertyList.items.map(
        ({id, name, code}) => ({
          id, name, code,
        })
      );
      const recommendations = productList.items.map(
        ({id, name}) => ({
          id, name
        })
      );
      const tags = tagList.items.map(
        ({id, name}) => ({
          id, name
        })
      );

      return context.commit('setData', {item: result, options: {categories, lines, properties, tags, recommendations}});
    } catch (e) {
      return context.commit('setApiError', {isError: true, status: e});
    }
  },
  async updateProduct(context, {token, id, data }) {
    try {
      await updateApiItem(`/products/${id}`, token, data)
    } catch (e) {
      console.error(e)
    }
  },
  async fixProduct(context, {token, id }) {
    try {
      await updateApiItem(`/products/6541ca47-b697-4e26-b8b4-220047f802a2`, token, {
        properties: {
          "skin_type":"",
          "application":"",
          "4_elements_for_whom":"",
          "fragrance_head":"",
          "fragrance_heart":"",
          "fragrance_base":"",
          "4_elements_product_type":"",
          "how_to_recycle":"",
          "4_elements_packaging":"",
          "ingredients_effect":"",
          "volume":"50 ml",
          "consumer_benefits":[],
          "4_elements_effects":"",
          "ingredients":[]
        },
      })
    } catch (e) {
      console.error(e)
    }
  },
  async postProduct(context, {token, data }) {
    await postApiItem('/products', token, data )
  },
  async deleteProduct(context, {token, id }) {
    try {
      await deleteApiItem('/products', token, id )
    } catch (e) {
      console.error(e)
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
