import {getApiItem, getIdFromIri, XHR_REQUEST} from "@/api/backend";
import moment from "moment";

const state = {
  item: {
    id: '',
    createdAt: '',
    number: ``,
    notes: '',
    status: '',
    data: {},
    billingAddress: {},
    deliveryAddress: {},
    deliveryData: {},
    paymentData: {},
    deliveryAmount: 0,
    discountAmount: 0,
    totalAmount: 0,
    items: [],
  },
  apiConnectionErr: {
    isError: false,
    status: null,
  },
};

const mutations = {
  setItem(state, item = {}) {
    state.item = item;
  },
  setApiError(state, hasError) {
    state.apiConnectionErr = hasError
  },
  resetItem(state) {
    state.item = {
      id: '',
      createdAt: '',
      number: ``,
      notes: '',
      status: '',
      data: {},
      billingAddress: {},
      deliveryAddress: {},
      deliveryData: {},
      paymentData: {},
      deliveryAmount: 0,
      discountAmount: 0,
      totalAmount: 0,
      items: [],
      histories: []
    };
    state.apiConnectionErr = {
      isError: false,
      status: null,
    };
  },
};

const actions = {
  async fetchItem(context, {token, id}) {
    const combineHistoryData = (item) => {
      return item.histories.map( item => ({
        createdAt: moment(item.createdAt).format('DD.MM.YYYY HH:mm'),
        status: item.status,
        action: item.action,
      }))
    };
    try {
      const item = (await getApiItem('/orders', token, id));
      const result = {
        id: item.id,
        createdAt: moment(String(item.createdAt)).format('YYYY-MM-DD HH:mm'),
        customerId: item.customer ? getIdFromIri(item.customer) : null,
        number: `#${item.number}`,
        notes: item.notes,
        status: item.status,
        data: item.data,
        billingAddress: item.billingAddress,
        deliveryAddress: item.deliveryAddress,
        deliveryData: item.deliveryData,
        paymentData: item.paymentData,
        deliveryAmount: (item.deliveryAmount * 0.01).toFixed(2),
        discountAmount: (item.discountAmount * 0.01).toFixed(2),
        itemsAmount: (item.itemsAmount * 0.01).toFixed(2),
        totalAmount: (item.totalAmount * 0.01).toFixed(2),
        items: item.items.map(
          ({name, grossTotal, qty, data}) => ({
            id: data.id,
            name,
            ean: data.ean,
            basePrice: (data.baseGrossPrice * 0.01).toFixed(2),
            price: (data.grossPrice * 0.01).toFixed(2),
            qty,
            total: (grossTotal * 0.01).toFixed(2),
          })
        ),
        histories: combineHistoryData(item)
      };

      return context.commit('setItem', result);
    } catch (e) {
      return context.commit('setApiError', {isError: true, status: e});
    }
  },
  async updateOrder({commit, rootState}, {orderId, data, status = 'cancel'}) {
    const API = XHR_REQUEST(`/orders/${orderId}/${status}`, 'put', rootState.Auth.token);
    try {
      await API.send(JSON.stringify(data));
      commit('setApiError', {isError: false, status: null});
    } catch (e) {
      console.error(e);
      commit('setApiError', {isError: true, status: e});
    }
  },
  async sendTransferForm({commit, rootState}, {orderId, data}) {
    const API = XHR_REQUEST(`/orders/${orderId}/transfer`, 'put', rootState.Auth.token);
    try {
      await API.send(JSON.stringify(data));
      commit('setApiError', {isError: false, status: null});
    } catch (e) {
      console.error(e);
      commit('setApiError', {isError: true, status: e});
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
