import {getApiItem, postApiItem, updateApiItem} from "@/api/backend";
import useAsyncStore from '@/composables/useAsyncWrapper'

const { useState, useActions, useMutations } = useAsyncStore
const { postItem, patchItem } = useActions
const fetchItem = async (context, cb) => {
  try {
    context.commit('setFormStatus', 'sending');
    const data = await cb();
    context.commit('setItem', {
      ...data,
      dateFrom: data.dateFrom ? new Date(data.dateFrom) : new Date(),
      dateTo: data.dateTo ? new Date(data.dateTo) : new Date(),
    })
    context.commit('setFormStatus', 'success');
  } catch (e) {
    console.error(e)
    context.commit('setFormStatus', 'danger');
    context.commit('handleSubmitForm', {
      status: 'danger',
      message: 'Coś poszło nie tak',
      module
    });
  }
}

const initialState = {
  item: {
    name: '',
    rates: [],
    dateFrom: new Date(),
    dateTo: new Date(),
    active: false
  }
}

const ratesItemStructure = {
  discount: 0,
  minGrossPrice: 0
}

const state = {
  ...useState,
  ...initialState
}

const mutations = {
  ...useMutations,
  newRatesItem(state) {
    state.item.rates.push(ratesItemStructure)
  },
  deleteRateItemFromArray(state, index) {
    const foundIndex = state.item.rates.findIndex((item, i) => i === index)
    if ( foundIndex !== -1) {
      state.item.rates.splice(index, 1)
    }
  },
  updateRateItem(state, { index, data }) {
    state.item.rates = [
      ...state.item.rates.filter( (item, i) => i !== index),
      data
    ]
  }
}

const actions = {
  resetForm(context) {
    context.commit('setItem', initialState.item)
  },
  async handleFetchItem(context, { token, id }) {
    await fetchItem(context, async () => await getApiItem(`/cart-promotions`, token, id) )
  },
  async handlePostItem(context, { token, data}) {
    await postItem(
      context,
      async () => await postApiItem('/cart-promotions', token, data),
      'Promocja progowa dodana pomyślnie'
    )
  },
  async handlePatchItem(context, { token, id, data }) {
    await postItem(
      context,
      async () => await updateApiItem(`/cart-promotions/${id}`, token, data),
      'Promocja progowa zaktualizowana pomyślnie'
    )
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
