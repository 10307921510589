import dateFormat from 'dateformat'
import {API, IRI} from "@/api/backend";

const state = {
  productDetails: {
    id: '',
    name: '',
    tax: '',
    taxRate: 0,
    baseGrossPrice: 0,
    baseNetPrice: 0,
    quantity: 0,
    createdAt: ''
  },
  apiConnectionErr: {
    isError: false,
    status: null,
  },
};

const mutations = {
  setProductDetails(state, details = {}) {
    state.productDetails = details;
  },
  setApiError(state, hasError) {
    state.apiConnectionErr = hasError;
  },
  resetData(state) {
    state.productDetails = {
      name: '',
        tax: '',
        taxRate: 0,
        baseGrossPrice: 0,
        baseNetPrice: 0,
        quantity: 0,
        createdAt: '',
    };
    state.apiConnectionErr = {
      isError: false,
      status: null,
    };
  },
};

const actions = {
  async getData(context, id) {
    try {
      const product = (await API.get(`/products/${id}`)).data;

      const productCategory = (await IRI.get(product.category)).data;
      const producerData = (await IRI.get(product.producer)).data;

      const result = {
        id: product.id,
        name: product.name,
        ean: product.ean,
        quantity: product.quantity,
        grossPrice: product.grossPrice * 0.01,
        baseGrossPrice: product.baseGrossPrice * 0.01,
        taxRate: product.taxRate * 0.01,
        createdAt: dateFormat(product.createdAt, 'yyyy-mm-dd"T"hh:mm'),
        updatedAt: dateFormat(product.updatedAt, 'yyyy-mm-dd"T"hh:mm'),
        category: {
          id: productCategory.id,
          name: productCategory.name,
        },
        producer: {
          id: producerData.id,
          name: producerData.name,
        },
      };

      return context.commit('setProductDetails', result);
    } catch ({response}) {
      return context.commit('setApiError', { isError: true, status: response.status });
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
