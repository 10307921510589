import {API, getApiList} from "@/api/backend";
import {factoryGetters, factoryMutations, factoryState} from "@/store/Modules/_factoryList";
import moment from "moment";

const state = JSON.parse(JSON.stringify(factoryState));

const mutations = {
  ...factoryMutations,
};

const getters = {
  ...factoryGetters,
};

const actions = {
  async fetchList(context, {token, page}) {
    try {
      const filters = context.getters.getFilters;
      const list = (await getApiList('/coupons', token, {...filters, page}));

      const result = list.items.map(
        ({ id, active, name, code, discount, grossAmount, dateFrom, dateTo }) => ({
          id,
          active: active ? 'TAK' : 'NIE',
          name,
          code,
          discount: (discount * 0.01).toFixed(2),
          amount: (grossAmount * 0.01).toFixed(2),
          dateFrom: moment(String(dateFrom)).format('YYYY-MM-DD HH:mm'),
          dateTo: moment(String(dateTo)).format('YYYY-MM-DD HH:mm'),
        })
      );

      return context.commit('setItems', {items: result, totalItems: list.totalItems});
    } catch ({response}) {
      return context.commit('setApiError', { isError: true, status: response?.status });
    }
  },
  async deleteCoupon({rootState}, {id}) {
    try {
      await API.delete(`/coupons/${id}`, {
        headers: {
          'Authorization': `Bearer ${rootState.Auth.token}`
        }
      })
    } catch (e) {
      console.error(e)
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
