import useState from "../Composables/useState";
import useMutations from "../Composables/useMutations";
import useActions from "../Composables/useActions";
const { updateState, updateForm } = useMutations
const { useFetch } = useActions

const nullishStates = {
  description: {
    short: '',
    long: ''
  },
  seo: {
    title: '',
    description: ''
  }
}

const state = () => {
  return {
    ...useState,
    form: {
      id: null,
      name: null,
      description: nullishStates['description'],
      seo: nullishStates['seo']
    },
    products: []
  }
}

const mutations = {
  updateState,
  updateForm
}

const actions = {
  async getLineInfo(context, lineId) {
    const data = (await useFetch(context, { method: 'get', url: `/product-lines/${lineId}` })).data;
    ['id', 'name', 'description', 'seo'].forEach( field => {
      context.commit('updateForm', {
        field,
        value: data[field] || nullishStates[field]
      })
    })
  },
  async getLineProducts(context, id) {
    const url = `/products?line=${id}`
    const data = await useFetch(context, { method: 'get', url })
    context.commit('updateState', {
      field: 'products',
      value: data.data?.['hydra:member'] ?? []
    })
  },
  async handleLineSave(context) {
    const { form } = context.state
    const { id } = form
    const method = id ? 'put' : 'post';
    const url = `/product-lines${ id ? '/' + id  : '' }`
    const data = await useFetch(
      context, {
        method,
        url,
        payload: {
          id,
          ...form
        }
      })
    console.log(data)
  },
  async handleLineDelete(context) {
    const { id } = context.state.form
    const data = await useFetch(context, {
      url: `/product-lines/${id}`,
      method: 'delete'
    })
    console.log(data)
    return data;
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
