import {getApiList} from "@/api/backend";
import {factoryGetters, factoryMutations, factoryState} from "@/store/Modules/_factoryList";

const state = JSON.parse(JSON.stringify(factoryState));

const mutations = {
  ...factoryMutations,
};

const getters = {
  ...factoryGetters,
};

const actions = {
  async fetchList(context, {token, page}) {
    try {
      const filters = context.getters.getFilters;
      const list = (await getApiList('/product-samples', token, {...filters, page}));

      const result = list.items.map(
        ({ id, name, active, ean }) => ({
          id,
          name,
          ean,
          active: active ? 'TAK' : 'NIE',
        })
      );

      return context.commit('setItems', {items: result, totalItems: list.totalItems});
    } catch (e) {
      return context.commit('setApiError', {isError: true, status: e});
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
