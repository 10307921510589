import {getApiList} from "@/api/backend";
import {factoryGetters, factoryMutations, factoryState} from "@/store/Modules/_factoryList";
import moment from "moment";

const state = JSON.parse(JSON.stringify(factoryState));

const mutations = {
  ...factoryMutations,
};

const getters = {
  ...factoryGetters,
};

const actions = {
  async fetchList(context, {token, page, query = {}}) {
    try {
      const filters = context.getters.getFilters;
      const list = (await getApiList('/orders', token, {...filters, page, ...query }));

      const result = list.items.map(
        ({id, status, number, invoiceNumber, totalAmount, itemsAmount, createdAt, billingAddress, data}) => ({
          id,
          status,
          number: `#${number}`,
          invoiceNumber,
          total: (totalAmount * 0.01).toFixed(2) + ' zł',
          products: (itemsAmount * 0.01).toFixed(2) + ' zł',
          date: moment(String(createdAt)).format('YYYY-MM-DD HH:mm'),
          avatar: `${billingAddress.firstName} ${billingAddress.lastName}`,
          customer: `${billingAddress.firstName} ${billingAddress.lastName}`,
          delivery: data.delivery,
          payment: data.payment
        })
      );

      return context.commit('setItems', {items: result, totalItems: list.totalItems});
    } catch ({response}) {
      return context.commit('setApiError', {isError: true, status: response?.status});
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
