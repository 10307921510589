import {API, XHR_REQUEST, updateApiItem} from "@/api/backend";


const weekFromNow = () => {
  const date = new Date()
  date.setDate( date.getDate() + 7 )
  return date
}
const _defaultState = {
  name: '',
  description: {
    short: '',
    long: '',
  },
  discount: 0,
  categories: [],
  lines: [],
  tags: [],
  freeShipping: false,
  active: false,
  dateFrom: new Date(),
  dateTo: weekFromNow(),
}

const state = {
  id: null,
  data: {
    ..._defaultState
  },
  apiStatus: null,
  postStatus: null,
  errorMessage: '',
}

const mutations = {
  updateField(state, { field, value }) {
    state.data[field] = value
  },
  setApiStatus(state, status) {
    state.apiStatus = status;
  },
  setPostStatus(state, status) {
    state.postStatus = status;
  },
  setErrorMessage(state, message) {
    state.errorMessage = message
  },
  resetState(state, payload = _defaultState) {
    state.data = {
      ...payload
    }
  }
}


const actions = {
  async mountedHook({commit, dispatch, rootState}, id = null ) {
    const authData = {token: rootState.Auth.token,
      page: 1}
    dispatch('ProductCategoryListModule/fetchList', authData, { root: true })
    dispatch('ProductLineListModule/fetchList', authData, { root: true })
    dispatch('ProductTagListModule/fetchList', authData, { root: true })

    if (id) {
      const resp = (await API.get(`/product-time-discounts/${id}`, {
        headers: {
          'Authorization': `Bearer ${rootState.Auth.token}`
        }
      })).data
      const promoData = {
        name: resp.name || '',
        description: {
          short: resp.description?.short ?? '',
          long: resp.description?.long ?? '',
        },
        discount: resp.discount,
        tags: resp.tags.map( tag => tag.split(`/api/v1/rest/product-tags/`).join('')),
        lines: resp.lines.map( line => line.split(`/api/v1/rest/product-lines/`).join('')),
        categories: resp.categories.map( category => category.split(`/api/v1/rest/product-categories/`).join('')),
        freeShipping: resp.freeShipping,
        active: resp.active,
        dateFrom: new Date(resp.dateFrom),
        dateTo: new Date(resp.dateTo)
      }
      commit('resetState', promoData)
    }

  },
  async submitTimeDiscount({commit, rootState}, id = null) {
    commit('setPostStatus', 'loading');
    commit('setErrorMessage','')

    const promoData = {
      ...state.data,
      tags: state.data.tags.map( tag => `/api/v1/rest/product-tags/${tag}`),
      lines: state.data.lines.map( line => `/api/v1/rest/product-lines/${line}`),
      categories: state.data.categories.map( category => `/api/v1/rest/product-categories/${category}`),
    }

    try {
      if (id) {
        console.log(id)
        await updateApiItem(`/product-time-discounts/${id}`, rootState.Auth.token, promoData);
      } else {
        await API.post(`/product-time-discounts`, promoData, {
          headers: {
            'Authorization': `Bearer ${rootState.Auth.token}`
          }
        })
      }

      commit('setPostStatus', 'success');
      return true
    } catch (e) {
      commit('setPostStatus', 'error');
      commit('setErrorMessage', e.response.data['hydra:description'])
    }
  },
  updateTimeDiscount(context) {

  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
