import axios from 'axios';

const config = require('@/api/config.json');

export const API = axios.create({
  baseURL: config.API_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/ld+json',
  }
});

export const XHR_REQUEST = (url, method, token) => {
  const req = new XMLHttpRequest();
  req.open(method,config.API_URL + url, true );
  req.setRequestHeader('Content-Type', 'application/json');
  req.setRequestHeader('Accept', 'application/ld+json');
  req.setRequestHeader('Authorization', `Bearer ${token}`);

  return req;
}

export const IRI = axios.create({
  baseURL: config.IRI_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/ld+json',
  }
});

export const LOGIN = axios.create({
  baseURL: config.LOGIN_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/ld+json',
  }
});

export const getIriFromId = (id, iriPrefix) => (id ? `${iriPrefix}/${id}` : null);

export const getIdFromIri = (iri) => (iri ? iri.split('/').at(-1) : null);

export const getApiList = async (endpoint, token, params = null) => {
  let url = endpoint;

  const config = {
    headers: {Authorization: `Bearer ${token}`}
  };

  // itemsPerPage=20
  if (!params) {
    params = {page: 1};
  }

  if (params && params === Object(params)) {
    const query = Object.entries(params).map(
      ([key, val]) => {
        if (Array.isArray(val)) {
          return [...new Set(val)].filter(el => !!el).map(el => `${key}[]=${encodeURIComponent(el)}`).join('&');
        }
        return `${key}=${encodeURIComponent(val)}`;
      }
    ).join('&');
    url = `${endpoint}?${query}`;
  }

  const response = (await API.get(url, config)).data;

  return {
    items: response['hydra:member'],
    totalItems: response['hydra:totalItems'],
  };
};

export const getApiItem = async (endpoint, token, id) => {
  const config = {
    headers: {Authorization: `Bearer ${token}`}
  };
  const url = `${endpoint}/${id}`;

  return (await API.get(url, config)).data;
};

export const getApiReport = async (endpoint, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
    responseType: 'blob',
  }

  return ( await API.get(endpoint, config) ).data
}

export const updateApiItem = async (endpoint, token, data ) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/merge-patch+json',
      'accept': 'application/ld+json'
    }
  }

  return ( await API.patch(endpoint, data, config )).data
}

export const postApiItem = async (endpoint, token, data ) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      'accept': 'application/ld+json'
    }
  }

  return ( await API.post(endpoint, data, config )).data
}
export const deleteApiItem = async (endpoint, token, id ) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      'accept': 'application/ld+json'
    }
  }

  return ( await API.delete(`${endpoint}/${id}`, config )).data
}
