import Vuex from 'vuex';
import Vue from 'vue';

// Modules
import Auth from "@/store/Modules/Auth";
import BundleListModule from '@/store/Modules/BundleList';
import CouponListModule from '@/store/Modules/CouponList';
import CouponAddModule from "@/store/Modules/CouponAdd";
import CustomerListModule from '@/store/Modules/CustomerList';
import GiftListModule from '@/store/Modules/GiftList';
import GiftManageModule from '@/store/Modules/GiftManage';
import OperatorListModule from '@/store/Modules/OperatorList';
import OrderListModule from '@/store/Modules/OrderList';
import PartnerListModule from '@/store/Modules/PartnerList';
import PartnerViewModule from '@/store/Modules/PartnerView';
import ProducerListModule from "@/store/Modules/ProducerList";
import ProductListModule from "@/store/Modules/ProductList";
import ProductCategoryListModule from "@/store/Modules/ProductCategoryList";
import ProductCategoryViewModule from "@/store/Modules/ProductCategoryView";
import ProductLineListModule from "@/store/Modules/ProductLineList";
import ProductLineManageModule from "@/store/Modules/ProductLineManage";
import ProductSampleListModule from "@/store/Modules/ProductSampleList";
import ProductPropertyListModule from "@/store/Modules/ProductPropertyList";
import ProductTagListModule from '@/store/Modules/ProductTagList';
import ProductTimeDiscountListModule from '@/store/Modules/ProductTimeDiscountList';
import ProductPromotionListModule from '@/store/Modules/ProductPromotionList';
import ProductPromotionManageModule from '@/store/Modules/ProductPromotionManage';
import ServiceListModule from '@/store/Modules/ServiceList';
import ServiceViewModule from '@/store/Modules/ServiceView';
import NewsletterListModule from "@/store/Modules/NewsletterList";
import NewsletterViewModule from "@/store/Modules/NewsletterView";



import CustomerViewModule from "@/store/Modules/CustomerView";
import OrderViewModule from "@/store/Modules/OrderView";
import ProductViewModule from "@/store/Modules/ProductView";
import ProductTagViewModule from "@/store/Modules/ProductTagView";

import ProductDetailsModule from "@/store/Modules/ProductDetails";

import ReportViewModule from "@/store/Modules/ReportView";

import PromoManage from "@/store/Modules/PromoManage";
import ProductGalleryList from "@/store/Modules/ProductGalleryList";
import ProductGalleryAddItem from "@/store/Modules/ProductGalleryAddItem";
import CartPromoViewModule from "@/store/Modules/CartPromotionView";
import CartPromoListModule from "@/store/Modules/CartPromotionList.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Auth,

    BundleListModule,
    CouponListModule,
    CouponAddModule,
    CustomerListModule,
    GiftListModule,
    GiftManageModule,
    OperatorListModule,
    OrderListModule,
    PartnerListModule,
    PartnerViewModule,
    ProducerListModule,
    ProductListModule,
    ProductCategoryListModule,
    ProductCategoryViewModule,
    ProductLineListModule,
    ProductLineManageModule,
    ProductPropertyListModule,
    ProductSampleListModule,
    ProductTagListModule,
    ProductTimeDiscountListModule,
    ProductPromotionListModule,
    ProductPromotionManageModule,
    ServiceListModule,
    ServiceViewModule,
    NewsletterListModule,
    NewsletterViewModule,

    CustomerViewModule,
    OrderViewModule,
    ProductViewModule,
    ProductTagViewModule,

    ProductDetailsModule,
    ReportViewModule,
    PromoManage,
    ProductGalleryList,
    ProductGalleryAddItem,
    CartPromoViewModule,
    CartPromoListModule
  }
});
