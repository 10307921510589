import {getApiList} from "@/api/backend";
import {factoryMutations, factoryState} from "@/store/Modules/_factoryList";

const state = JSON.parse(JSON.stringify(factoryState));

const mutations = {
  ...factoryMutations,
};

const actions = {
  async fetchList(context, {token, page}) {
    try {
      const list = (await getApiList('/services', token, {page}));
      const result = list.items.map(
        ({ id, name, active, fixedPrice, grossPrice }) => ({
          id,
          name,
          active: active ? 'TAK' : 'NIE',
          fixedPrice: fixedPrice ? 'TAK' : 'NIE',
          price: (grossPrice * 0.01).toFixed(2),
        })
      );

      return context.commit('setItems', {items: result, totalItems: list.totalItems});
    } catch ({response}) {
      return context.commit('setApiError', {isError: true, status: response?.status});
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
