<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">{{ tableTitle }}</h3>
        </div>
        <div class="col text-right">
          <base-button v-for="(button, index) in tableButtons"
                       type="default"
                       icon size="sm"
                       :key="index"
                       @click="clickButton(button.link)">
            <span class="btn-inner--icon"><i :class="button.icon"></i></span>
            <span class="btn-inner--text">{{ button.label }}</span>
          </base-button>
          <base-button v-if="filterOptions.length"
                       type="primary"
                       size="sm"
                       @click="modals.form = true">
            <span class="btn-inner--icon"><i class="fas fa-sliders-h"></i></span>
            <span class="btn-inner--text">Filtry</span>
          </base-button>
        </div>
      </div>
      <div class="row align-items-center py-1">
        <div class="col-8">
          <span>
            <filter-tags :value="filters"
                         tag-type="filter"
                         @change="changeFilters"
            ></filter-tags>
          </span>
        </div>
        <div class="col-4 text-right">
        </div>
      </div>
    </div>

    <el-table class="table el-table--no-overflow responsive table-flush"
              header-row-class-name="thead-light"
              :data="tableData">
      <el-table-column v-for="(column, index) in tableColumns"
                       :label="column.label"
                       :prop="column.prop"
                       :key="index"
                       :min-width="column.minWidth"
                       :sortable="column.sortable"
                       v-bind="column"
      >
        <template v-slot="{row}" v-if="column.link === true">
          <router-link :to="{name: routerView, params: {id: row.id}}" class="font-weight-bold">
            {{ row[column.prop] }}
          </router-link>
        </template>
        <template v-slot="{row}" v-else-if="column.prop === 'image'">
          <img :src="row.image" class="table-image" />
        </template>
        <template v-slot="{row}" v-else-if="column.prop === 'avatar'">
          <avatar :username="row.avatar" :size="32" :lighten="90"></avatar>
        </template>
        <template v-slot="{row}" v-else-if="column.prop === 'status'">
          <div class="badge" :class="[`${getOrderBadgeType(row.status)}`]">{{ row.status }}</div>
        </template>
        <template v-slot="{row}" v-else-if="column.prop === 'shipping'">
          <div :class="row.payment.code === 'on_delivery' ? 'badge badge-danger text-white' : ''">
            {{ row.payment.method }}
          </div>
        </template>
        <template v-slot="{row}" v-else-if="column.prop === 'actions'">
          <BaseDropdown>
            <template #title>Akcje</template>
            <li v-for="action in column.actions" :key="`option-${column.id}-${action.code}`" class="pl-4" @click="handleDropdown({
              'code': action.code,
              'id': row.id
            })">
              {{ action.label }}
            </li>
          </BaseDropdown>
        </template>
      </el-table-column>

    </el-table>

    <div class="card-footer py-4 d-flex justify-content-end justify-content-sm-between" v-if="pagination.totalItems">
      <div>
        <p class="card-category">{{ footerTotal }}</p>
      </div>
      <base-pagination v-model="pagination.currentPage"
                       :total="pagination.totalItems"
                       :per-page="pagination.perPage"
                       @input="nextPage">
      </base-pagination>
    </div>

    <form role="form" @submit.prevent="addFilter">
      <modal :show.sync="modals.form"
             size="lg"
             body-classes="p-0">
        <h3 slot="header" class="mb-0">Filtrowanie</h3>

        <card type="secondary"
              header-classes="bg-transparent pb-5"
              body-classes="px-lg-5 py-lg-5"
              class="border-0 mb-0">

          <template>
            <div class="row">
              <div class="col-md-6">
                <base-input label="Nazwa pola">
                  <el-select v-model="formModal.field"
                             filterable
                             placeholder="Wybierz pole">
                    <el-option v-for="option in filterOptions"
                               :key="option.field"
                               :label="option.label"
                               :value="option.field">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-md-6">
                <base-input type="text"
                            label="Tekst do wyszukania"
                            placeholder=""
                            v-model="formModal.search"
                >
                </base-input>
              </div>
            </div>
          </template>
        </card>

        <template slot="footer">
          <base-button type="link" class="ml-auto" @click="modals.form = false">Zamknij</base-button>
          <base-button type="primary" native-type="submit">Dodaj filtr</base-button>
        </template>

      </modal>
    </form>

    <div class="col">
      <div class="alert alert-danger" v-if="connectionError.isError">
        {{ handleError }}
      </div>
    </div>
  </div>
</template>

<script>
import {Option, Select, Table, TableColumn} from "element-ui";
import Avatar from "@/components/Avatar";
import FilterTags from "@/components/Inputs/FilterTags";
import {polishNumerals} from "@/util/gramma";
import BaseDropdown from "@/components/BaseDropdown";

export default {
  name: 'TableNavigation',
  props: {
    tableTitle: {
      type: String,
      default: 'Table title',
    },
    tableButtons: {
      type: [Array, Object],
      default: null,
    },
    tableColumns: {
      type: [Array, Object],
      default: null,
    },
    tableData: {
      type: [Array, Object],
      default: () => [],
    },
    routerView: {
      type: String,
    },
    connectionError: {
      type: Object,
      default: () => {
        return {isError: false};
      },
    },
    handleError: {
      type: String,
      default: 'Error while getting data from API',
    },
    pagination: {
      type: Object,
      default: () => {
        return {
          totalItems: 0,
          perPage: 20,
          currentPage: 1,
        };
      },
    },
    filterOptions: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Array,
      default: () => [],
    }
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    Avatar,
    FilterTags,
    BaseDropdown
  },
  data() {
    return {
      modals: {
        form: false,
      },
      formModal: {
        field: '',
        search: '',
      },
    };
  },
  computed: {
    footerTotal() {
      return polishNumerals(this.pagination.totalItems, ['Dostępna {num} pozycja', 'Dostępne {num} pozycje', 'Dostępnych {num} pozycji']);
    }
  },
  methods: {
    getOrderBadgeType(status) {
      switch (status) {
        case 'processing':
          return 'badge-info';
        case 'pending':
          return 'badge-warning';
        case 'cancelled':
          return 'badge-danger text-white';
        case 'paid':
          return 'badge-primary text-white'
        case 'in_progress':
          return 'badge-secondary'
        case 'shipped':
          return 'badge-success'
        case 'delivered':
          return 'badge-success'
        default:
          return ''
      }
    },
    nextPage(page) {
      this.$emit('page', page);
    },
    clickButton(link) {
      this.$router.push({name: link});
    },
    addFilter() {
      const field = this.formModal.field;
      const label = this.filterOptions.find(op => op.field === field).label;
      this.filters.push({
        field: field,
        label: label,
        value: this.formModal.search,
      });
      this.formModal = {field: '', search: ''};
      this.modals.form = false;
      this.$emit('filters', this.filters);
    },
    changeFilters(filters) {
      this.$emit('filters', filters);
    },
    handleDropdown(e) {
      this.$emit(e.code, e.id);
    }
  },
};
</script>

<style lang="scss">
.table-image {
  max-height: 40px;
}
.el-table.el-table--no-overflow {
  &, & .cell, .el-table__body-wrapper {
    overflow: visible !important;
  }
}

.el-tag--filter {
  margin-right: 0.6em;
}
</style>
