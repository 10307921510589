import {API, XHR_REQUEST} from "@/api/backend";

const _defaultNewsletterInformation = {
  email: '',
  active: true,
}

const state = {
  newsletterInformation: { ..._defaultNewsletterInformation },
  apiStatus: null,
  postStatus: null
}

const mutations = {
  updateNewsletterField(state, { field, value }) {
    try {
      switch ( typeof value) {
        case 'object' : state.newsletterInformation[field] = { ...state.newsletterInformation[field], ...value }; break;
        default: state.newsletterInformation[field] = value;break;
      }
    } catch (e) {
      console.error(e)
      return false;
    }
  },
  setApiStatus(state, status) {
    state.apiStatus = status;
  },
  setPostStatus(state, status) {
    state.postStatus = status;
  },
  setNewsletterInformation(state, {newsletterInformation, apiStatus}) {
    state.newsletterInformation = newsletterInformation;
    state.apiStatus = apiStatus;
  },
  resetNewsletterInfo(state) {
    state.newsletterInformation = { ..._defaultNewsletterInformation };
    state.apiStatus = null;
    state.postStatus = null;
  },
}

const actions = {
  async fetchNewsletterInformation({commit, state}, newsletterId ){
    try {
      commit('setApiStatus', 'loading');
      const resp = (await API.get(`/newsletter-subscribers/${newsletterId}`));
      commit('setNewsletterInformation', { newsletterInformation: { ...state.newsletterInformation, ...resp.data } , apiStatus: 'success' });
    } catch (e) {
      commit('setNewsletterInformation', { newsletterInformation: {}, apiStatus: 'error' });
    }
  },
  async submitForm({commit, rootState}, newsletterId) {
    commit('setPostStatus', 'loading');

    const API = XHR_REQUEST(`/newsletter-subscribers/${newsletterId.id}`, newsletterId ? 'put': 'post', rootState.Auth.token);

    try {
      await API.send(JSON.stringify(state.newsletterInformation));
      commit('setPostStatus', 'success');
    } catch (e) {
      console.log(e)
      commit('setPostStatus', 'error');
    }
  },
  async submitAddForm({commit, rootState}, newsletterId) {
    commit('setPostStatus', 'loading');

    const API = XHR_REQUEST(`/newsletter-subscribers`, newsletterId ? 'put': 'post', rootState.Auth.token);

    try {
      await API.send(JSON.stringify(state.newsletterInformation));
      commit('setPostStatus', 'success');
      return true
    } catch (e) {
      console.log(e)
      commit('setPostStatus', 'error');
    }
  },
  async deleteForm({commit, rootState}, newsletterId) {
    console.log(newsletterId.id)
    commit('setPostStatus', 'loading');
    const API = XHR_REQUEST(`/newsletter-subscribers/${newsletterId.id}`, newsletterId ? 'delete': 'post', rootState.Auth.token);

    try {
      await API.send(JSON.stringify(state.newsletterInformation));
      commit('setPostStatus', 'success');
    } catch (e) {
      console.log(e)
      commit('setPostStatus', 'error');
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
